var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"class-add"},[_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.classId ? '修改班级' : '创建班级'))])]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"115px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"班级名称","prop":"className"}},[_c('el-input',{attrs:{"placeholder":"请输入4-24字的班级名称","clearable":""},model:{value:(_vm.form.className),callback:function ($$v) {_vm.$set(_vm.form, "className", $$v)},expression:"form.className"}})],1),_c('el-form-item',{attrs:{"label":"班主任"}},[(_vm.teachers.length)?_c('ul',{staticClass:"teachers"},_vm._l((_vm.teachers),function(item,index){return _c('li',{key:item.studentId,staticClass:"teacher"},[_c('span',[_vm._v(_vm._s(item.studentName))]),_c('img',{staticClass:"close",attrs:{"src":require("@/assets/common/close_icon.png")},on:{"click":function($event){return _vm.removeTeacher(item, index)}}})])}),0):_vm._e(),_c('el-button',{on:{"click":_vm.selectAdviser}},[_vm._v("请选择班主任")])],1),(_vm.status > 0 && _vm.status < 3)?_c('el-form-item',{attrs:{"label":"培训时间","prop":"classTime"}},[_c('el-date-picker',{staticStyle:{"width":"164px"},attrs:{"disabled":"","type":"date","placeholder":"选择开始日期","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.form.classBeginTime),callback:function ($$v) {_vm.$set(_vm.form, "classBeginTime", $$v)},expression:"form.classBeginTime"}}),_vm._v(" 至 "),_c('el-date-picker',{staticStyle:{"width":"164px"},attrs:{"type":"date","placeholder":"选择结束日期","value-format":"yyyy-MM-dd HH:mm:ss","picker-options":{
            disabledDate: function (time) {
              return time.getTime() < Date.parse(new Date(_vm.form.classBeginTime)) + 3600 * 1000 * 24
            },
          }},model:{value:(_vm.form.classEndTime),callback:function ($$v) {_vm.$set(_vm.form, "classEndTime", $$v)},expression:"form.classEndTime"}})],1):_c('el-form-item',{attrs:{"label":"培训时间","prop":"classTime"}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":"yyyy-MM-dd HH:mm:ss","picker-options":{
            disabledDate: function (time) {
              return time.getTime() < Date.now() - 3600 * 1000 * 24
            },
          }},model:{value:(_vm.form.classTime),callback:function ($$v) {_vm.$set(_vm.form, "classTime", $$v)},expression:"form.classTime"}})],1),_c('el-form-item',{ref:"pic",attrs:{"label":"班级封面图","prop":"pic"}},[_c('upload-image',{attrs:{"upload-img":_vm.$refs.pic,"width-size":_vm.widthSize,"height-size":_vm.heightSize},model:{value:(_vm.form.pic),callback:function ($$v) {_vm.$set(_vm.form, "pic", $$v)},expression:"form.pic"}}),_c('p',{staticClass:"tip"},[_vm._v("图片大小在564 X 290，支持扩展名：.png .jpg .jpeg，建议上传图片大小5MB")])],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("保存")])],1)],1)],1),_c('class-adviser-dialog',{ref:"classAdviserDialog",on:{"handleTeachersChange":_vm.handleTeachersChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }