<!--
 * @Description: 创建班级
 * @Author: xiawenlong
 * @Date: 2021-04-26 10:03:44
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-12-16 19:47:02
-->
<template>
  <div class="class-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ classId ? '修改班级' : '创建班级' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="班级名称" prop="className">
          <el-input
            v-model="form.className"
            placeholder="请输入4-24字的班级名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="班主任">
          <ul v-if="teachers.length" class="teachers">
            <li v-for="(item, index) in teachers" :key="item.studentId" class="teacher">
              <span>{{ item.studentName }}</span>
              <img
                src="@/assets/common/close_icon.png"
                class="close"
                @click="removeTeacher(item, index)"
              />
            </li>
          </ul>
          <el-button @click="selectAdviser">请选择班主任</el-button>
        </el-form-item>
        <el-form-item v-if="status > 0 && status < 3" label="培训时间" prop="classTime">
          <el-date-picker
            v-model="form.classBeginTime"
            style="width:164px"
            disabled
            type="date"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="form.classEndTime"
            style="width:164px"
            type="date"
            placeholder="选择结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="{
              disabledDate: time => {
                return time.getTime() < Date.parse(new Date(form.classBeginTime)) + 3600 * 1000 * 24
              },
            }"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-else label="培训时间" prop="classTime">
          <el-date-picker
            v-model="form.classTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="{
              disabledDate: time => {
                return time.getTime() < Date.now() - 3600 * 1000 * 24
              },
            }"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item ref="pic" label="班级封面图" prop="pic">
          <upload-image
            v-model="form.pic"
            :upload-img="$refs.pic"
            :width-size="widthSize"
            :height-size="heightSize"
          ></upload-image>
          <p class="tip">图片大小在564 X 290，支持扩展名：.png .jpg .jpeg，建议上传图片大小5MB</p>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <class-adviser-dialog
      ref="classAdviserDialog"
      @handleTeachersChange="handleTeachersChange"
    ></class-adviser-dialog>
  </div>
</template>
<script>
import { classCreate, classUpdate, classInfo } from '@/api/class'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
import ClassAdviserDialog from './components/ClassAdviserDialog'
export default {
  name: 'ClassAdd',
  components: { UploadImage, ClassAdviserDialog },
  data() {
    return {
      widthSize: 564,
      heightSize: 290,
      rules: {
        className: [
          { required: true, message: '请输入4-24字的班级名称', trigger: 'blur' },
          { min: 4, max: 24, message: '请输入4-24字的班级名称', trigger: 'blur' },
        ],
        classTime: [{ required: true, message: '请选择培训时间', trigger: 'change' }],
        pic: [{ required: true, message: '请上传png、jpg格式的图片', trigger: 'change' }],
      },
      form: {},
      loading: false,
      teachers: [],
      classId: '',
      status: '',
    }
  },
  mounted() {
    this.classId = this.$route.params.classId
    this.status = this.$route.query.status
    if (this.classId) this.getClassInfo()
  },
  methods: {
    async getClassInfo() {
      const [res, err] = await to(classInfo({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.form = { ...res.data, classTime: [res.data.classBeginTime, res.data.classEndTime] }
      this.teachers = this.form.headTeacher
    },
    selectAdviser() {
      this.$refs.classAdviserDialog.show(this.teachers)
    },
    handleTeachersChange(val) {
      this.teachers = val
    },
    removeTeacher(item, index) {
      this.teachers.splice(index, 1)
    },
    submit() {
      this.classId ? this.update() : this.create()
    },
    create() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(
            classCreate({
              ...this.form,
              classBeginTime: this.form.classTime[0],
              classEndTime: this.form.classTime[1],
              headTeacherIds: this.teachers.map(item => item.studentId),
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('创建成功')
          this.$router.push('/class/list')
        }
      })
    },
    update() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(
            classUpdate({
              ...this.form,
              classId: this.classId,
              classBeginTime: this.form.classTime[0],
              classEndTime:
                this.status > 0 && this.status < 3
                  ? this.form.classEndTime
                  : this.form.classTime[1],
              headTeacherIds: this.teachers.map(item => item.studentId),
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('修改成功')
          this.$router.push('/class/list')
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.class-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
  .teachers {
    li {
      box-sizing: border-box;
      float: left;
      position: relative;
      cursor: pointer;
      min-width: 80px;
      padding: 0 13px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      color: #333333;
      margin-right: 25px;
      margin-bottom: 10px;
      .close {
        position: absolute;
        width: 14px;
        height: 14px;
        right: -5px;
        top: -5px;
      }
    }
  }
}
</style>
