/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-23 09:59:43
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-24 10:11:54
 */
export default {
  classSelect: '/course/manage/classes/select', // 班级下拉列表
  headteacherList: '/course/manage/class/headteacher/list', // 查询班主任列表
  classCreate: '/course/manage/class/create', // 创建班级
  classUpdate: '/course/manage/class/update', // 编辑班级
  classList: '/course/manage/classes/list', // 班级列表
  classInfo: '/course/manage/class', // 班级详情
  classStudents: '/course/manage/class/students/page', // 班级已有学员
  classDetailStudents: '/course/manage/class/detail/students/page', // 班级详情学员
  studentsByOrg: '/course/manage/class/student/add/listByOrg', // 按学员列表添加-学员列表
  studentsByClass: '/course/manage/class/student/add/listByClass', // 按现有班级添加-学员列表
  studentsClasses: '/course/manage/class/student/add/listClasses', // 按现有班级添加-班级列表
  studentsDept: '/course/manage/dept/tree', // 按部门添加-部门列表
  studentByDept: '/course/manage/dept/student/page', // 部门学院列表
  studentsRemove: '/course/manage/class/students/remove', // 删除学员
  studentsAdd: '/course/manage/class/students/add', // 添加学员
  classCourses: '/course/manage/class/courses/list', // 班级已有课程
  classCourseAddOwn: '/course/manage/class/course/addOwn', // 班级添加自建课程
  classCourseAddBuy: '/course/manage/class/course/addBuy', // 班级添加已购课程
  classCourseAddShop: '/course/manage/class/course/addShop', // 班级添加课程商城课程
  classCourseAddPlan: '/course/manage/class/course/addTrainPlan', // 班级添加培养计划课程
  courseBuyList: '/course/manage/product/buy/course/toClass/v2', // 添加课程的已购课程列表
  courseShopList: '/course/manage/courses/shoppingMall', // 添加课程的课程商城列表
  coursePlanList: '/course/manage/trainPlan/org/toPageByOrg', // 添加课程的培养计划列表
  coursePlanDetail: '/course/manage/trainPlan/getDetail', // 添加课程的培养计划详情
  classSettleCourseList: '/course/manage/class/settle/course/list', // 班级结算-课程列表
  classRemove: '/course/manage/class/course/remove', // 删除班级课程
  classAskPrice: '/course/manage/class/start/queryPrice', // 开班询价
  classOffer: '/course/manage/class/start', // 开班
  classCancel: '/course/manage/class/cancel', // 取消班级
  classDelete: '/course/manage/class/forzen', // 删除班级
  classDetailCourse: '/course/manage/class/detail/courses/list', //班级课程详情
  classStudyhistory: '/course/manage/class/student/studyhistory', //班级学习详情
  studentExport: '/course/manage/class/student/studyhistory/export', //班级下学员列表导出
  studentStudyhistory: '/course/manage/class/student/personal/studyhistory', //个人学习详情
  personalExport: '/course/manage/class/student/personal/studyhistory/export', //个人学习详情导出
  studentInfo: '/course/manage/class/student/info/', //学员个人信息
  courseSelect: '/course/manage/class/course/selectlist', //课程下拉列表
  sectionSelect: '/course/manage/course/section/selectlist', //节下拉列表
  courseStudy: '/course/manage/class/course/studyhistory', //个人详情课程列表
  courseExport: '/course/manage/class/course/studyhistory/export', //个人详情课程列表导出
  clockStatistics: '/course/manage/homepage/punch/record/query', //个人详情打卡统计
  clockExport: '/course/manage/homepage/punch/record/export', //个人详情打卡统计导出
  examStatistics: '/course/manage/exam/statistics/query', //个人详情考试统计
  examExport: '/course/manage/exam/statistics/export', //个人详情考试统计导出
  sectionStudyhistory: '/course/manage/class/course/section/studyhistory', //个人详情章节轨迹记录
  sectionExport: '/course/manage/class/course/section/studyhistory/export', //个人详情章节轨迹记录导出

  classCourseCreditUpdate: '/course/manage/class/course/credit/update', //设置学分
  classCourseExamList: '/course/manage/class/course/exam/list', //设置考试列表
  classCourseExamUpdate: '/course/manage/class/course/exam/update', //分配考试
  classCourseExamRequireUpdate: '/course/manage/class/course/examRequire/update', //设置是否需要考试
  studentSelectList: '/course/manage/class/student/select/list', //学员下拉列表
  searchClassClock: '/course/manage/class/study/config/query', //查询班级打卡
  setClassClock: '/course/manage/class/study/config', //设置班级打卡
  classStudentImportTemplate: '/course/manage/excel/template/export', //班级学员导入模板下载（身份证导入）
  classStudentImport: '/course/manage/excel/student/idcard/import', //excel通过身份证导入班级学员

  setClassGetRule: '/course/admin/evaluate/config/get', //查看班级评论设置
  setClassUpdateRule: '/course/admin/evaluate/config/update', //修改班级评论设置

  getEvaluateRecordList: '/course/admin/evaluate/record/getPage', //查看班级评论列表
  evaluateExport: '/course/admin/evaluate/record/export', //导出班级评论列表
  classCourseSort: '/course/manage/class/course/sort', //班级已有课程排序
  
  //人脸识别记录
  manageClass: '/course/manage/class', //根据ID查询班级
  identifyFaceCheckRecord: '/course/identify/face/check/record', //查询人脸识别统计
  identifyFaceCheckRecordExport: '/course/identify/face/check/record/export', //查询人脸识别统计
  
  
  classCommentExport: '/course/manage/class/comment/export', //企业班级评论导出

}
