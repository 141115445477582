/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-23 10:00:18
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-24 08:47:23
 */
import config from './config/class'
import axios from '@/utils/request'
export const classSelect = params => axios.post(config.classSelect, params)
export const headteacherList = params => axios.post(config.headteacherList, params)
export const classCreate = params => axios.post(config.classCreate, params)
export const classUpdate = params => axios.post(config.classUpdate, params)
export const classList = params => axios.post(config.classList, params)
export const classInfo = params => axios.post(config.classInfo, params)
export const classStudents = params => axios.post(config.classStudents, params)
export const classDetailStudents = params => axios.post(config.classDetailStudents, params)
export const studentsByOrg = params => axios.post(config.studentsByOrg, params)
export const studentsByClass = params => axios.post(config.studentsByClass, params)
export const studentsClasses = params => axios.post(config.studentsClasses, params)
export const studentsDept = params => axios.post(config.studentsDept, params)
export const studentByDept = params => axios.post(config.studentByDept, params)
export const studentsRemove = params => axios.post(config.studentsRemove, params)
export const studentsAdd = params => axios.post(config.studentsAdd, params)
export const classCourses = params => axios.post(config.classCourses, params)
export const classCourseAddOwn = params => axios.post(config.classCourseAddOwn, params)
export const classCourseAddBuy = params => axios.post(config.classCourseAddBuy, params)
export const classCourseAddShop = params => axios.post(config.classCourseAddShop, params)
export const classCourseAddPlan = params => axios.post(config.classCourseAddPlan, params)
export const courseBuyList = params => axios.post(config.courseBuyList, params)
export const courseShopList = params => axios.post(config.courseShopList, params)
export const coursePlanList = params => axios.post(config.coursePlanList, params)
export const coursePlanDetail = params => axios.post(config.coursePlanDetail, params)
export const classSettleCourseList = params => axios.post(config.classSettleCourseList, params)
export const classRemove = params => axios.post(config.classRemove, params)
export const classAskPrice = params => axios.post(config.classAskPrice, params)
export const classOffer = params => axios.post(config.classOffer, params)
export const classCancel = params => axios.post(config.classCancel, params)
export const classDelete = params => axios.post(config.classDelete, params)
export const classDetailCourse = params => axios.post(config.classDetailCourse, params)
export const classStudyhistory = params => axios.post(config.classStudyhistory, params)
export const studentExport = params =>
  axios.post(config.studentExport, params, { responseType: 'blob' })
export const studentStudyhistory = params => axios.post(config.studentStudyhistory, params)
export const studentInfo = params => axios.post(config.studentInfo, params)
export const personalExport = params =>
  axios.post(config.personalExport, params, { responseType: 'blob' })
export const courseSelect = params => axios.post(config.courseSelect, params)
export const sectionSelect = params => axios.post(config.sectionSelect, params)

export const classCourseCreditUpdate = params => axios.post(config.classCourseCreditUpdate, params)
export const classCourseExamList = params => axios.post(config.classCourseExamList, params)
export const classCourseExamUpdate = params => axios.post(config.classCourseExamUpdate, params)
export const classCourseExamRequireUpdate = params =>
  axios.post(config.classCourseExamRequireUpdate, params)
export const studentSelectList = params => axios.post(config.studentSelectList, params)

export const searchClassClock = params => axios.post(config.searchClassClock, params)
export const setClassClock = params => axios.post(config.setClassClock, params)
export const courseStudy = params => axios.post(config.courseStudy, params)
export const courseExport = params =>
  axios.post(config.courseExport, params, { responseType: 'blob' })
export const clockStatistics = params => axios.post(config.clockStatistics, params)
export const clockExport = params =>
  axios.post(config.clockExport, params, { responseType: 'blob' })
export const examStatistics = params => axios.post(config.examStatistics, params)
export const examExport = params => axios.post(config.examExport, params, { responseType: 'blob' })
export const sectionStudyhistory = params => axios.post(config.sectionStudyhistory, params)
export const sectionExport = params =>
  axios.post(config.sectionExport, params, { responseType: 'blob' })
export const classStudentImportTemplate = params =>
  axios.post(config.classStudentImportTemplate, params, { responseType: 'blob' })
export const classStudentImport = params => axios.post(config.classStudentImport, params)

export const setClassGetRule = params => axios.post(config.setClassGetRule, params)
export const setClassUpdateRule = params => axios.post(config.setClassUpdateRule, params)
export const getEvaluateRecordList = params => axios.post(config.getEvaluateRecordList, params)
export const evaluateExport = params =>
  axios.post(config.evaluateExport, params, { responseType: 'blob' })
export const classCourseSort = params => axios.post(config.classCourseSort, params)

export const manageClass = params => axios.post(config.manageClass, params)
export const identifyFaceCheckRecord = params => axios.post(config.identifyFaceCheckRecord, params)
export const identifyFaceCheckRecordExport = params =>
  axios.post(config.identifyFaceCheckRecordExport, params, { responseType: 'blob' })

  export const classCommentExport = params =>
  axios.post(config.classCommentExport, params, { responseType: 'blob' })
